@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.form {
  $block-name: &;

  &__box {
  }

  &__cell {
    width: calc(33.3333% - 6px);

    @include mq($to: lg) {
      width: calc(100% - 6px);

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &--full-width {
      width: 100%;
      max-width: 100%;
    }

    &:nth-child(2),
    &:nth-child(3) {
      // TODO: Sorry
      .field-text__title {
        opacity: 0;

        @include mq($to: lg) {
          display: none;
        }
      }
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 0 8px;

    &:not(:last-child) {
      margin-bottom: 50px;

      @include mq($to: md) {
        margin-bottom: 40px;
      }
    }

    @include mq($to: lg) {
      flex-direction: column;
    }
  }

  &__control {
    margin-top: 50px;

    @include mq($to: md) {
      margin-top: 40px;
    }

    @include mq($to: sm) {
      margin-top: 30px;
      text-align: center;

      .button {
        justify-content: center;
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
