/*
	Form input placeholder text

	example:

	input,
	textarea {
		@include input-placeholder {
			color: $grey;
		}
	}
*/

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin field-error-text($color: $color-danger) {
  color: $color;
  font-size: 12px;
  line-height: line-height(14, 11);
  display: block;
  margin-top: 8px;
}
