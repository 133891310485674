@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.field-text {
  $block-name: &;

  &__title {
    color: $white-100;
    font-family: $font-family--headings;
    font-size: $font-size;
    font-style: normal;
    font-weight: $font-weight-l;
    line-height: $line-height-s;
    letter-spacing: -0.4px;
    margin-bottom: 10px;
  }

  &__title-name {
  }

  &__inner {
    input,
    textarea {
      @include input-placeholder {
        color: fade-out($color: $white-100, $amount: 0.4);
      }

      font-family: $font-family;
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      min-height: 49px;
      margin: 0;
      border-radius: 8px;
      border: none;
      border-bottom: 2px solid rgb(255 255 255 / 48%);
      padding: 14px 10px;
      font-size: $font-size-2xs;
      line-height: $line-height-l;
      background-color: transparent;
      appearance: none;
      transition: border-color $transition-time;
      color: $white-100;
      word-break: normal;
      font-weight: 500;

      &:focus,
      &:hover {
        outline: 0;
        border-color: $white-100;
      }

      &::-webkit-search-cancel-button,
      &::-webkit-search-decoration,
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        appearance: none;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    textarea {
      $textarea-height: 129px;

      height: auto;
      min-height: calc(#{$textarea-height} + 2px);
      border: 2px solid rgb(255 255 255 / 48%);
      resize: none;
    }

    &--readonly {
      /* stylelint-disable-next-line no-descending-specificity */
      input {
        cursor: not-allowed;

        &:focus,
        &:hover {
          // border-color: $gray-lighten;
        }
      }
    }

    &--height-90 {
      /* stylelint-disable-next-line no-descending-specificity */
      textarea {
        $textarea-height: 90px;

        min-height: calc(#{$textarea-height} + 2px);
      }
    }
  }

  &__error {
    @include field-error-text($white-100);
  }

  &--variant-light {
    #{$block-name} {
      &__title {
        color: $purple-500;
      }

      &__title-name {
      }

      &__inner {
        input,
        textarea {
          @include input-placeholder {
            color: fade-out($color: $purple-500, $amount: 0.4);
          }

          color: $purple-500;
          border-bottom-color: $purple-100;

          &:focus,
          &:hover {
            border-color: $purple-200;
          }
        }

        textarea {
          border-color: $purple-100;
        }
      }

      &__error {
        @include field-error-text($purple-500);
      }
    }
  }
}
